import * as React from 'react';
import Layout from '../../components/layout/layout';
import Seo from '../../components/seo';
import Qa from '../../components/qa/qa';

const QADriveRecorderConnectPage = () => (
  <Layout>
    <Seo title="よくあるお問い合わせ　ドラレコConnect" />
    <Qa
      id="driverecorderConnect"
      title="ドラレコConnect"
      linkList={[
        {
          label: 'ユピテル製フォークリフト専用ドライブレコーダー(FDR-820)',
          link: 'https://www.yupiteru.co.jp/products/biz_fdr/fdr-820/',
        },
      ]}
    ></Qa>
  </Layout>
);

export default QADriveRecorderConnectPage;
